import { gql, useApolloClient } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { usePrevious } from 'react-use'

import {
  useDateFilter,
  useFirstDateOfYear,
  useYoyDates,
  useYtdEndDate,
} from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { ILoadMoreOptionType } from '../../types'

type IDataType = Record<
  'listLocationMetricValues' | 'listLocationGroupMetricValues',
  {
    nodes: [
      {
        metricData: Record<
          string,
          {
            name: string
            unit: 'CENT'
            value: number
          }
        >
      },
    ]
  }
>

interface IVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter:
    | {
        location_ids: number[]
        metrics: string[]
      }
    | {
        location_group_ids: number[]
        metrics: string[]
      }
  hasGroupBy: boolean
}

type IStateDataType = Record<
  string,
  {
    isLoaded: true
    value: number
  }
>

const query = gql`
  query MetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        metricData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        metricData
      }
    }
  }
`

const useQuery = (
  type: 'current' | 'yoy' | 'ytd' | 'yytd',
  setData: Dispatch<SetStateAction<IStateDataType>>,
  originVariables: IVariablesType | null,
) => {
  const apollo = useApolloClient()
  const [variables, setVariables] = useState<IVariablesType | null>(null)

  useEffect(() => {
    const timeout = setTimeout(setVariables, 500, originVariables)

    return () => {
      clearTimeout(timeout)
    }
  }, [variables, setVariables, originVariables])

  useEffect(() => {
    if (!variables) return

    const watchedQuery = apollo.watchQuery<IDataType, IVariablesType>({
      query,
      variables,
    })
    const sub = watchedQuery.subscribe({
      next({ data, partial }) {
        if (partial) return

        const metricData =
          (data.listLocationMetricValues || data.listLocationGroupMetricValues)
            .nodes[0]?.metricData || {}
        const prefix = type === 'current' ? '' : `${type}_`

        setData((prevData) => ({
          ...prevData,
          ...variables.iFilter.metrics.reduce(
            (result, field) => ({
              ...result,
              [`${prefix}${field}`]: {
                isLoaded: true,
                value: metricData[field]?.value,
              },
            }),
            {},
          ),
        }))
      },
    })

    return () => {
      sub.unsubscribe()
    }
  }, [type, setData, variables, apollo])
}

const useBbbPAndLLoadMore = () => {
  const dateFilter = useDateFilter()
  const yoyDates = useYoyDates(dateFilter.startDate, dateFilter.endDate)
  const ytdStartDate = useFirstDateOfYear(
    dateFilter.endDate ? moment.utc(dateFilter.endDate) : moment(),
  )
  const ytdEndDate = useYtdEndDate()
  const yytdDates = useYoyDates(ytdStartDate, ytdEndDate)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const prevDateFilter = usePrevious(
    (dateFilter.startDate && dateFilter.endDate && dateFilter) || null,
  )
  const prevGroupFilter = usePrevious(groupFilter)
  const [data, setData] = useState<IStateDataType>({})
  const [visibleFields, setVisibleFields] = useState<string[]>([])
  const { variables } = useVariables()

  useEffect(() => {
    if (!prevGroupFilter || !prevDateFilter) return

    if (!_.isEqual(prevGroupFilter, groupFilter)) {
      setVisibleFields([])
      setData({})
      return
    }

    if (!_.isEqual(prevDateFilter, dateFilter)) setData({})
  }, [dateFilter, groupFilter, setData, setVisibleFields])

  const { currentVariables, yoyVariables, ytdVariables, yytdVariables } =
    useMemo(() => {
      const newFields = visibleFields
        .filter((f) => !data[f]?.isLoaded)
        .reduce(
          (result, key) => (result.includes(key) ? result : [...result, key]),
          [] as string[],
        )
      const currentFields = newFields.filter((f) => !/^(yoy|ytd|yytd)_/.test(f))
      const yoyFields = newFields
        .filter((f) => /^yoy_/.test(f))
        .map((f) => f.replace(/^yoy_/, ''))
      const ytdFields = newFields
        .filter((f) => /^ytd_/.test(f))
        .map((f) => f.replace(/^ytd_/, ''))
      const yytdFields = newFields
        .filter((f) => /^yytd_/.test(f))
        .map((f) => f.replace(/^yytd_/, ''))

      return {
        currentVariables:
          (dateFilter.startDate &&
            dateFilter.endDate &&
            groupFilter &&
            currentFields.length !== 0 && {
              iStartDate: dateFilter.startDate,
              iEndDate: dateFilter.endDate,
              iFilter: hasGroupBy
                ? {
                    location_group_ids: groupFilter.ids,
                    metrics: currentFields,
                  }
                : {
                    location_ids: groupFilter.ids,
                    metrics: currentFields,
                  },
              hasGroupBy,
            }) ||
          null,
        yoyVariables:
          (yoyDates.yoyStartDate &&
            yoyDates.yoyEndDate &&
            groupFilter &&
            yoyFields.length !== 0 && {
              iStartDate: yoyDates.yoyStartDate,
              iEndDate: yoyDates.yoyEndDate,
              iFilter: hasGroupBy
                ? {
                    location_group_ids: groupFilter.ids,
                    metrics: yoyFields,
                  }
                : {
                    location_ids: groupFilter.ids,
                    metrics: yoyFields,
                  },
              hasGroupBy,
            }) ||
          null,
        ytdVariables:
          (ytdStartDate &&
            ytdEndDate &&
            groupFilter &&
            ytdFields.length !== 0 && {
              iStartDate: ytdStartDate,
              iEndDate: ytdEndDate,
              iFilter: hasGroupBy
                ? {
                    location_group_ids: groupFilter.ids,
                    metrics: ytdFields,
                  }
                : {
                    location_ids: groupFilter.ids,
                    metrics: ytdFields,
                  },
              hasGroupBy,
            }) ||
          null,
        yytdVariables:
          (yytdDates.yoyStartDate &&
            yytdDates.yoyEndDate &&
            groupFilter &&
            yytdFields.length !== 0 && {
              iStartDate: yytdDates.yoyStartDate,
              iEndDate: yytdDates.yoyEndDate,
              iFilter: hasGroupBy
                ? {
                    location_group_ids: groupFilter.ids,
                    metrics: yytdFields,
                  }
                : {
                    location_ids: groupFilter.ids,
                    metrics: yytdFields,
                  },
              hasGroupBy,
            }) ||
          null,
      }
    }, [
      visibleFields,
      dateFilter,
      yoyDates,
      ytdStartDate,
      ytdEndDate,
      yytdDates,
      groupFilter,
      hasGroupBy,
    ])

  useQuery('current', setData, currentVariables)
  useQuery('yoy', setData, yoyVariables)
  useQuery('ytd', setData, ytdVariables)
  useQuery('yytd', setData, yytdVariables)

  return {
    data,
    loadMore: useCallback(
      (options: ILoadMoreOptionType[]) => {
        const getRequiredCodes = variables.bbbPnl?.getRequiredCodes

        if (!getRequiredCodes) return

        const newFields = options
          .map(({ id, fields }) =>
            fields.map((field) => getRequiredCodes(id)[field]).flat(),
          )
          .flat()

        setVisibleFields(newFields)
      },
      [variables],
    ),
  }
}

export default useBbbPAndLLoadMore
