import { IRouteType } from '../../types'
import bibibopExpenses from './bibibopExpenses'
import bibibopFlash from './bibibopFlash'
import bibibopHaccps from './bibibopHaccps'
import bibibopHome from './bibibopHome'
import bibibopIntraday from './bibibopIntraday'
import bibibopKpis from './bibibopKips'
import bibibopPAndL from './bibibopPAndL'
import bibibopSystemwideOverview from './bibibopSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
      {
        title: 'Flash',
        link: '/:brand/flash',
      },
      {
        title: 'KPIs',
        link: '/:brand/kpis',
      },
      {
        title: 'Rational',
        link: '/:brand/rational',
      },
      {
        title: 'Expenses',
        link: '/:brand/expenses',
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
      {
        title: 'Today',
        link: '/:brand/today',
      },
      {
        title: 'P&L',
        children: [
          {
            title: 'Store Opportunities',
            link: '/:brand/store-opportunities',
          },
          {
            title: 'P&L Table',
            link: '/:brand/p-and-l',
          },
        ],
      },
    ],
  },
  {
    title: 'Finance',
    children: [
      {
        title: 'P&L',
        link: '/:brand/corporate-p-and-l',
      },
      {
        title: 'Executive Summary',
        link: '/:brand/executive-summary',
      },
      {
        title: 'Company Opportunities',
        link: '/:brand/company-opportunities',
      },
      {
        title: 'Rankings',
        link: '/:brand/p-and-l-rankings',
      },
      {
        title: 'Charts',
        children: [
          {
            title: 'Profit',
            link: '/:brand/profit-after-controllable',
          },
          {
            title: 'F&P',
            link: '/:brand/food-and-paper',
          },
          {
            title: 'Comp Sales',
            link: '/:brand/comp-sales',
          },
          {
            title: 'Comp Performance',
            link: '/:brand/comp-store-sales-trends',
          },
        ],
      },
    ],
  },
]

export default [
  bibibopSystemwideOverview,
  bibibopFlash,
  bibibopKpis,
  bibibopHome,
  bibibopHaccps,
  bibibopIntraday,
  bibibopExpenses,
  bibibopPAndL,
]
