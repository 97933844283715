import { v4 as uuidv4 } from 'uuid'

import Table from 'pared/pages/ExpoAi/Table'
import Trend from 'pared/pages/ExpoAi/Trend'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'

export default function handleKpiAnalyzerAnswers(responseData: any) {
  if (
    responseData?.kpiValues ||
    responseData?.storeKpiRanking ||
    responseData?.directorKpiRanking
  ) {
    const answerMessage: IMessage = {
      id: uuidv4(),
      from: FROM_EXPO,
      content: (
        <Table
          data={
            responseData?.kpiValues ||
            responseData?.storeKpiRanking ||
            responseData?.directorKpiRanking
          }
        />
      ),
    }

    return answerMessage
  }

  if (responseData?.kpiTrends) {
    const answerMessage: IMessage = {
      id: uuidv4(),
      from: FROM_EXPO,
      content: <Trend data={responseData?.kpiTrends} />,
    }

    return answerMessage
  }

  return null
}
