import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getBrandId } from 'pared/utils/brand'

import { IApiType } from '../../types'

const QUERY_NAME = 'listLocationGroupsForAdmin'

const query = gql`
  query ($iFilter: JSON!) {
    ${QUERY_NAME}: ${QUERY_NAME}(
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        locationCode
        locationName
        locationGroupId
        locationGroupName
        locationGroupType
        locationGroupMappingId
      }
    }
  }
`

export interface INode {
  locationId: string
  locationCode: string
  locationName: string
  locationGroupId: string
  locationGroupName: string
  locationGroupType: string
  locationGroupMappingId: number
}

export interface IResponse {
  [QUERY_NAME]: {
    nodes: INode[]
  }
}

export interface IVariables {
  iFilter: {
    brand_ids: number[]
  }
}

export const listLocationGroupsForAdminConfigs = {
  locationId: 'number',
  locationCode: 'string',
  locationName: 'change-location-button',
  locationGroupId: 'number',
  locationGroupName: 'change-location-group-button',
  locationGroupType: 'string',
} as const

const useListLocationGroupsForAdmin = (): IApiType => {
  const brandId = getBrandId()
  const { data, loading, refetch } = useQuery<IResponse, IVariables>(query, {
    variables: {
      iFilter: { brand_ids: [brandId] },
    },
  })

  return {
    data: useMemo(() => {
      const nodes = data?.[QUERY_NAME].nodes
      if (!nodes) return null

      const locationGroupTypes = nodes.reduce((acc, cur) => {
        const locationGroupType = cur.locationGroupType
        return {
          ...acc,
          [locationGroupType]: {
            locationGroupType: cur.locationGroupType,
          },
        }
      }, {} as { [locationGroupType: string]: { locationGroupType: string } })

      const locationGroups = nodes.reduce((acc, cur) => {
        const locationGroupId = cur.locationGroupId
        return {
          ...acc,
          [locationGroupId]: {
            locationGroupId: cur.locationGroupId,
            locationGroupName: cur.locationGroupName,
            locationGroupType: cur.locationGroupType,
          },
        }
      }, {} as { [locationGroupId: string]: { locationGroupId: string; locationGroupName: string; locationGroupType: string } })

      const locationGroupTypeHeaders = Object.values(locationGroupTypes).map(
        (n) => {
          return {
            ...n,
            id: n.locationGroupType,
            parentId: 'root',
          }
        },
      )

      const locationGroupHeaders = Object.values(locationGroups).map((n) => {
        return {
          ...n,
          id: n.locationGroupId,
          parentId: n.locationGroupType,
        }
      })

      const locations = nodes.map((n) => {
        return {
          ...n,
          id: n.locationName,
          parentId: n.locationGroupId,
        }
      })

      return [
        ...locationGroupTypeHeaders,
        ...locationGroupHeaders,
        ...locations,
      ]
    }, [data]),
    loading,
    refetch,
  }
}

export const locationGroupsConfigs = {
  ...listLocationGroupsForAdminConfigs,
}

const useLocationGroups = () => {
  const listLocationGroupsForAdmin = useListLocationGroupsForAdmin()

  return listLocationGroupsForAdmin
}

export default useLocationGroups
