import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrand } from 'pared/utils/brand'

interface IProps {
  startDate?: string
  endDate?: string
}

// FIX ME: FSS and SMG should be seperated
const CorporateFssTable = ({ startDate, endDate }: IProps) => {
  const brand = getBrand()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]

  const isSmgBrand = ['wingitnorth', 'mwb', 'fw_wingstop'].includes(
    brand.toLowerCase(),
  )

  return (
    <Container>
      <Header>{isSmgBrand ? 'SMG' : 'FSS'}</Header>
      <CustomizedReport
        reportName={
          isSmgBrand ? 'LIST_LOCATION_SMG_TABLE' : 'CORPORATE_FSS_TABLE'
        }
        inputParams={{
          startDate,
          endDate,
          locationGroupId: brandLocationGroupId,
        }}
        csvFileName={isSmgBrand ? 'rankings-smg' : 'rankings-fss'}
      />
    </Container>
  )
}

export default CorporateFssTable

const Container = styled.div`
  padding: 0 0 60px 0;
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const Header = styled.div`
  margin: 0px;
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  line-height: 52px;

  @media ${MOBILE_WIDTH} {
    padding-bottom: 25px;
  }
`
