import { useMemo } from 'react'

import { IApiDataType } from '../types'
import useCompanyFilter from './useBbbCompanyFilter'

const useExecutiveSummaryFilter = () => {
  const { data, loading } = useCompanyFilter()

  return {
    data: useMemo((): IApiDataType => {
      const values = data?.values.filter((v) => v.parentId === 'root')

      if (!values) return null

      return {
        values,
        defaultValue: [values[0].id],
      }
    }, [data]),
    loading,
  }
}

export default useExecutiveSummaryFilter
