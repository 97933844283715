import useLocationGroups, { locationGroupsConfigs } from './useLocationGroups'
import useLocations, { locationsConfigs } from './useLocations'
import useSystemwideOverview, {
  systemwideOverviewConfigs,
} from './useSystemwideOverview'

export const configs = {
  systemwideOverview: systemwideOverviewConfigs,
  locationGroups: locationGroupsConfigs,
  locations: locationsConfigs,
}

export const api = {
  systemwideOverview: useSystemwideOverview,
  locationGroups: useLocationGroups,
  locations: useLocations,
}
