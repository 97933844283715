import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../variables'
import Link, { IPropsType as ILinkPropsType } from './Link'
import ChangeLocationButton from './components/administration/ChangeLocationButton'
import ChangeLocationGroupButton from './components/administration/ChangeLocationGroupButton'
import EditLocationButton from './components/administration/EditLocationButton'

export interface IBasePropsType {
  value: unknown
  onClick?: string
  row?: any
  refetch?: any
}

export type IPropsType =
  | (IBasePropsType & {
      type: 'price'
      decimal?: number
    })
  | (IBasePropsType & {
      type: 'percent'
      decimal?: number
      reverse?: boolean
    })
  | (IBasePropsType & {
      type: 'number'
      decimal?: number
      reverse?: boolean
    })
  | (IBasePropsType & {
      type: 'string'
    })
  | (IBasePropsType & {
      type: 'link'
    })
  | (IBasePropsType & {
      type: 'change-location-button'
    })
  | (IBasePropsType & {
      type: 'change-location-group-button'
    })
  | (IBasePropsType & {
      type: 'edit-location-button'
    })

const displayDangerString = (number: string | number, isDanger: boolean) =>
  isDanger ? `(${number})` : number

const Text = styled.span<{
  isDanger?: boolean
}>(({ isDanger }) => {
  if (isDanger) return `color: ${COLORS.Pomodoro}`

  return ''
})

const Format = ({ value, onClick, refetch, ...props }: IPropsType) => {
  const { variables } = useVariables()
  const defaultProps = useMemo(
    () => ({
      onClick: () => {
        const action = onClick && _.get(variables, onClick)

        if (action) action(value)
      },
    }),
    [value, onClick, variables],
  )

  switch (props.type) {
    case 'price': {
      if (value === '-' || value === undefined || value === null) return <>-</>

      const numberValue =
        typeof value === 'string' ? parseFloat(value) : (value as number)

      return (
        <Text {...defaultProps} isDanger={numberValue < 0}>
          {toUsdString(numberValue / 100, props.decimal)}
        </Text>
      )
    }

    case 'percent': {
      const isDanger = props.reverse
        ? (value as number) > 0
        : (value as number) < 0

      return (
        <Text {...defaultProps} isDanger={isDanger}>
          {toPercentString(value as string, props.decimal)}
        </Text>
      )
    }

    case 'number': {
      if (value === '-' || value === undefined || value === null) return <>-</>

      if (typeof value === 'string') {
        const numberValue = parseFloat(value)
        const isDanger = props.reverse ? numberValue > 0 : numberValue < 0

        return (
          <Text {...defaultProps} isDanger={isDanger}>
            {displayDangerString(
              Math.abs(numberValue).toLocaleString('en-US', {
                minimumFractionDigits: props.decimal || 0,
                maximumFractionDigits: props.decimal || 0,
              }),
              isDanger,
            )}
          </Text>
        )
      }

      const isDanger = props.reverse
        ? (value as number) > 0
        : (value as number) < 0

      return (
        <Text {...defaultProps} isDanger={isDanger}>
          {displayDangerString(
            Math.abs(value as number).toLocaleString('en-US', {
              minimumFractionDigits: props.decimal || 0,
              maximumFractionDigits: props.decimal || 0,
            }),
            isDanger,
          )}
        </Text>
      )
    }

    case 'string':
      return <Text {...defaultProps}>{value}</Text>

    case 'link':
      if (!value) return null

      return <Link {...defaultProps} {...(value as ILinkPropsType)} />

    case 'change-location-button':
      return <ChangeLocationButton row={props.row} refetch={refetch} />

    case 'change-location-group-button':
      return <ChangeLocationGroupButton row={props.row} refetch={refetch} />

    case 'edit-location-button':
      return <EditLocationButton row={props.row} refetch={refetch} />

    default:
      return null
  }
}

export default Format
