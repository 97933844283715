import { gql } from '@apollo/client'

interface ILocationGroupDailyInventoryAnalysisNodeType {
  locationGroupId: number
  locationGroupName: string
  locationId: number
  locationName: string
  hasNonCountedItems: boolean
}

export interface ILocationGroupDailyInventoryAnalysisType {
  listLocationGroupDailyInventoryAnalysis: {
    nodes: ILocationGroupDailyInventoryAnalysisNodeType[]
  }
}

export const GET_CORPORATE_DAILY_INVENTORY_ANALYSIS = gql`
  query ListLocationGroupDailyInventoryAnalysis(
    $iLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationGroupDailyInventoryAnalysis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: { location_group_ids: [$iLocationGroupId] }
    ) {
      nodes {
        locationGroupId
        locationGroupName
        locationId
        locationName
        hasNonCountedItems
      }
    }
  }
`

export const GET_CORPORATE_COGS_SUMMARY = (brand: string) => {
  let queryName = 'corporateCogsSummaryV2'
  switch (brand) {
    case 'mwb':
      queryName = 'corporateCogsSummaryMwb'
      break
    case 'bibibop':
      queryName = 'corporateCogsSummaryBbb'
      break
    default:
      queryName = 'corporateCogsSummaryV2'
  }

  return gql`
    query getCorporateCogsSummary(
      $iLocationGroupId: Int!
      $iBrandLocationGroupId: Int!
      $iStartDate: Date!
      $iEndDate: Date!
    ) {
      corporateCogsSummary: ${queryName} (
        iLocationGroupId: $iLocationGroupId
        iBrandLocationGroupId: $iBrandLocationGroupId
        iStartDate: $iStartDate
        iEndDate: $iEndDate
      ) {
        nodes {
          item
          locations
          annualOpportunityCost
          ${brand === 'bibibop' ? 'averageWeeklyOpportunityCost' : ''}
          opportunityCost
          totalNetSales
          annualTotalNetSales
        }
      }
    }
  `
}

export const GET_CORPORATE_DELIVERY_SUMMARY = gql`
  query getCorporateDeliverySummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateDeliverySummary: corporateDeliverySummaryV2(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        type
        locations
      }
    }
  }
`

export const GET_CORPORATE_LABOR_SUMMARY = gql`
  query getCorporateLaborSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateLaborSummary: corporateLaborSummaryV2(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        hour
        locations
        locationNum
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

export const GET_CORPORATE_LOSS_PREVENTION_SUMMARY = gql`
  query getCorporateLossPreventionSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateLossPreventionSummary: corporateLossPreventionSummaryV2(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        type
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

export const GET_CORPORATE_SALESMANSHIP_SUMMARY = gql`
  query getCorporateSalesmanshipSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateSalesmanshipSummary: corporateSalesmanshipSummaryV2(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        area
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

export const GET_CORPORATE_TEAM_SUMMARY = gql`
  query getCorporateTeamSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
  ) {
    corporateTeamSummary: corporateTeamSummaryV2(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
    ) {
      nodes {
        locationInfo
        thirtyDayTerminationCount
        thirtyDayHireCount
        thirtyDayTurnoverRate
        sixtyDayTerminationCount
        sixtyDayHireCount
        sixtyDayTurnoverRate
        ninetyDayTerminationCount
        ninetyDayHireCount
        ninetyDayTurnoverRate
        corporateThirtyDayTurnoverRate
        corporateSixtyDayTurnoverRate
        corporateNinetyDayTurnoverRate
        corporateCurrentAnnualizedHourlyTurnoverPercent
        corporateCurrentAnnualizedSalariedTurnoverPercent
        corporatePastAnnualizedHourlyTurnoverPercent
        corporatePastAnnualizedSalariedTurnoverPercent
      }
    }
  }
`

export const GET_CORPORATE_GUEST_SUMMARY = gql`
  query getCorporateGuestSummary(
    $iLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateGuestSummary(
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        avgAcrScore
        nationalAvgAcrScore
        reviews
      }
    }
  }
`

export const GET_CORPORATE_VOIDS_SUMMARY = gql`
  query getCorporateVoidsSummary(
    $iLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateVoidsSummary(
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locations
        voidsCheckPercent
        voidsDetails
      }
    }
  }
`
