import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import getDateRanges from 'pared/data/getDateRanges'

import { useDateFilter } from '../../../dateFilter'

type IDateType = 'year' | 'quarter' | 'period' | 'week'

type IDateOptionNodeType<type extends IDateType = IDateType> = {
  id: type extends 'year'
    ? `${number}`
    : type extends 'quarter'
    ? `Q${number}`
    : type extends 'period'
    ? `P${number}`
    : `P${number}W${number}`
  parentId: `${number} ${type} root`
  type: type
  year: number
  displayName: string
  dateRange: {
    startDate: moment.Moment
    startDateStr: string
    endDate: moment.Moment
    endDateStr: string
  }
}

type IDateOptionType =
  | { id: 'root' }
  | {
      id: IDateOptionNodeType<'year'>['parentId']
      parentId: 'root'
      displayName: string
    }
  | {
      id: IDateOptionNodeType['parentId']
      parentId: IDateOptionNodeType<'year'>['parentId']
      displayName: string
    }
  | IDateOptionNodeType

export interface IDataType {
  date?: {
    options: IDateOptionType[]
    getInfo: (diff: number) =>
      | undefined
      | {
          displayName: string
          dateRange: IDateOptionNodeType['dateRange']
          yoy?: {
            displayName?: string
            dateRange?: IDateOptionNodeType['dateRange']
          }
        }
    yearIds: IDateOptionNodeType<'year'>['id'][]
    periodIds: IDateOptionNodeType<'period'>['id'][]
    periodCalendar: IDateOptionNodeType<'period'>[][]
  }
}

const useDate = (): IDataType => {
  const { search } = useLocation()
  const state = useAsync(getDateRanges, [search])
  const dateFilter = useDateFilter()

  const dateOptions = useMemo(() => {
    if (!state.value) return

    const dates = Object.values(state.value.dateRangeMap).sort((a, b) =>
      b.key.localeCompare(a.key),
    )
    const yearDates = dates.filter((d) => d.type === 'year')

    return [
      {
        id: 'root' as const,
      },
      ...yearDates.map((d) => ({
        id: `${d.index as number} year root` as const,
        parentId: 'root' as const,
        displayName: d.index.toString(),
      })),
      ...yearDates.map((d) => ({
        id: `${d.index as number}` as const,
        parentId: `${d.index as number} year root` as const,
        type: 'year' as const,
        year: d.index as number,
        displayName: 'year',
        dateRange: {
          startDate: d.startDate,
          startDateStr: d.startDateStr,
          endDate: d.endDate,
          endDateStr: d.endDateStr,
        },
      })),
      ...(['quarter', 'period', 'week'] as const)
        .map((type) => {
          const filteredDates = dates.filter((d) => d.type === type)

          return [
            ...yearDates.map((d) => ({
              id: `${d.index as number} ${type} root` as const,
              parentId: `${d.index as number} year root` as const,
              displayName: _.upperFirst(type),
            })),
            ...filteredDates.map((d) => {
              const { id, prefix } = (() => {
                switch (type) {
                  case 'quarter':
                    return {
                      id: `Q${d.index as number}`,
                      prefix: `Q${d.index as number}`,
                    } as const
                  case 'period':
                    return {
                      id: `P${d.index as number}`,
                      prefix: `P${d.index as number}`,
                    } as const
                  case 'week':
                    const prefix = d.index
                      .toString()
                      .replace(/(\d+)-(\d+)/, (_, s1, s2) => `P${s1} W${s2}`)

                    return {
                      id: `${
                        prefix.replace(/ /g, '') as `P${number}W${number}`
                      }`,
                      prefix,
                    } as const
                }
              })()

              return {
                id,
                parentId: `${d.year} ${type} root` as const,
                type,
                year: d.year,
                displayName: `${prefix} (${d.startDate.format(
                  'M/D/YY',
                )} to ${d.endDate.format('M/D/YY')})`,
                dateRange: {
                  startDate: d.startDate,
                  startDateStr: d.startDateStr,
                  endDate: d.endDate,
                  endDateStr: d.endDateStr,
                },
              }
            }),
          ]
        })
        .flat(),
    ]
  }, [state.value])

  return {
    date: useMemo(() => {
      if (!dateOptions) return

      const infos: Record<number, IDateOptionNodeType> = (() => {
        const option = dateOptions.find(
          (o) =>
            'dateRange' in o &&
            o.dateRange.startDateStr === dateFilter.startDate &&
            o.dateRange.endDateStr === dateFilter.endDate,
        )

        if (!(option && 'type' in option)) return {}

        const options = dateOptions.filter(
          (o) => 'type' in o && o.type === option.type && 'dateRange' in o,
        )
        const optionIndex = options.findIndex((o) => o === option)

        if (optionIndex === -1) return {}

        return options.reduce(
          (result, o, index) => ({
            ...result,
            [optionIndex - index]: o,
          }),
          {},
        )
      })()

      const periods = dateOptions.filter(
        (o) => 'type' in o && o.type === 'period',
      ) as IDateOptionNodeType<'period'>[]
      const maxPeriod = Math.max(
        ...periods.map((d) => parseInt(d.id.replace(/P/, '')), 10),
      )
      const periodCalendar = periods.reduce((result, period) => {
        if (result.length === 0) return [[period]]

        const prevPeriod = result.slice(-1)[0].slice(-1)[0]

        if (prevPeriod.year === period.year)
          return [...result.slice(0, -1), [period, ...result.slice(-1)[0]]]

        return [...result, [period]]
      }, [] as IDateOptionNodeType<'period'>[][])

      return {
        options: dateOptions,
        getInfo: (diff: number) => {
          const info = infos[diff]

          if (!info) return

          const yoy = Object.values(infos).find(
            (i) => info.year - 1 === i.year && info.id === i.id,
          )

          return {
            displayName: info.id,
            dateRange: info.dateRange,
            yoy: yoy && {
              displayName: yoy.id,
              dateRange: yoy.dateRange,
            },
          }
        },
        yearIds: dateOptions
          .filter((o) => 'type' in o && o.type === 'year')
          .map((d) => d.id as `${number}`),
        periodIds: Array.from({ length: maxPeriod }).map(
          (_, index) => `P${index + 1}` as const,
        ),
        periodCalendar,
      }
    }, [dateOptions, dateFilter]),
  }
}

export default useDate
